import './styles/hero.scss'

function Hero(){
    return (
        <div id="hero">
            <div id="text">
            <h1>ENGR 407 Portfolio</h1>
            <h2>Jonathan Zavialov</h2>
            <p>
                This website is my submission for the ENGR 407 Personal Portfolio project. 
                I took this project as an opportunity to improve my web development skills 
                using styles and technologies I have never tried before, while exploring my 
                take on the class and its projects in a format that allows me to express 
                myself creatively.
            </p>
            </div>
            <div className='arrow bounce'></div>
        </div>
    )
}

export default Hero;
