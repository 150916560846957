import './styles/navbar.scss';

function NavBar({ links }){
    return (
        <>
            {
                window.innerWidth > 700 &&
                <div id="navbar">
                    <p>PROJECTS:</p>
                    {
                        links.map((link, i) => 
                            <a href={`#${link.href}`} key={i}>{link.display}</a>
                        )
                    }
                </div>
            }
        </>
    )
}

export default NavBar;
