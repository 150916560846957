import './styles/projectsection.scss';
import StarRatings from 'react-star-ratings';

function ProjectSection({ align, image, title, id, content, rating }){
    return (
        <div className={`project-section-container`} id={id}>
            <div className={`project-section ${window.innerWidth < 700 ? 'mobile' : align}`}>
                <img src={image} alt={title} />
                <div className="content">
                    <h1>{title}</h1>
                    {
                        content.map((paragraph, i) => 
                            <p key={i}>{paragraph}</p>
                        )
                    }
                </div>
            </div>
            <div className='rating'>
                <p>RATING:</p>
                <StarRatings
                    rating={rating}
                    starDimension='20px'
                    starEmptyColor="white"
                />
            </div>
        </div>
    )
}

export default ProjectSection;