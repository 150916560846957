import './styles/index.scss'
import Hero from './Hero';
import NavBar from './NavBar';
import ProjectSection from './ProjectSection';

import originLabs from './assets/originlabs.jpeg';
import masonDixie from './assets/masondixie.png';
import boalsburg from './assets/boalsburg.jpg';
import buttonMaker from './assets/buttonmaker.png';
import maternityThrone from './assets/maternitythrone.png';

const sections = [
  {
    'href': 'explore-boalsburg',
    'display': 'Explore Boalsburg',
    'image': boalsburg,
    'rating': 4.5,
    'content': [
      'One of the first projects of the class was to explore a city near State College. I was assigned Boalsburg, so me and a few classmates drove down one weekend. The town was very quiet, but had a lot of very interesting attractions. We explored a graveyard, which claims to be the birthplace of Memorial Day, a military museum that had many tanks and warships on display, and a bar, where we were able to talk to many locals and get their views on the area.',
      'We learned that many of the locals were not born there, but rather moved because they were seeking a quieter place in their older age. Many of the residents where upset that State College has been growing, as younger and louder people keep moving in and high rises continue to expand.',
      'This project offered a refreshing departure from my usual classes, prompting me to explore a nearby town which I otherwise would probably have never visited. Unlike traditional coursework, this hands-on experience allowed me to break free from textbooks and lectures, uncovering new activities and areas to explore. The exploration was a great intro to the class, as it allowed me to get to know my classmates better, as well as introducing the teaching style.'
    ]
  },
  {
    'href': 'junkyard-wars',
    'display': 'JunkYard Wars',
    'image': originLabs,
    'rating': 5,
    'content': [
      'The next project was to assemble a solution to an environmental problem using junk. My group decided to make a water filter using various junk purchased from Goodwill. We were able to make a working filter, which we tested by pouring dirty water through it. While we weren\'t confident enough to drink it, it seemed to be significantly cleaner than the water we started with.',
      'I particularly enjoyed that this project took place in Origin Labs in the Eric J. Barron building. I was aware of this lab, but never had the opportunity to work in it, and the experience of learning how to use some of the power tools and being able to fabricate a working solution from what would have otherwise been trash was very rewarding.'
    ]
  },
  {
    'href': 'guest-speaker',
    'display': 'Guest Speaker',
    'image': masonDixie,
    'rating': 4,
    'content': [
      'Listening to Ayeshah Abuelhiga, the founder of Mason Dixie Foods, was a very interesting exploration into the entrepreneurial journey. Her insights into the challenges of launching a business and navigating the complexities of getting her product into grocery stores offered a valuable perspective. I enjoyed hearing about how the pandemic impacted her business, and what she had to do to overcome the challenges that came with it. What resonated most were Abuelhiga\'s steadfast values, particularly her commitment to diversity and the role of women in business. Her story not only provided a practical understanding of entrepreneurship but also highlighted the importance of tenacity, adaptability, and a commitment to core values when you run your own business.',
      'During the session, I had the opportunity to ask a question about the challenges of entrepreneurship. Her response provided valuable insights applicable to my own business venture.'
    ]
  },
  {
    'href': 'crash-n-burn',
    'display': 'Crash N Burn',
    'image': buttonMaker,
    'rating': 4,
    'content': [
      'The Crash N Burn project was one of my favorite projects. We started with an investment of five dollars, and were tasked with making as much money as possible within one class period. This was a very fun and refreshing experience, because of how fast paced and creative it allowed us to be. We were able to make a decent amount of money within that period by selling free samples and reselling snacks that we bought.',
      'Using this money, we had a longer period of time to establish a business venture and make as much money as possible. My group decided take a few different approaches, including selling walking tacos. We were able to sell a large amount, but realized we would have to pivot in order to make a larger profit margin. We purchased a button maker, and were able to take custom orders from clubs and make a large profit off these buttons. We also benefitted from the UMass game and Homecoming Parade, which we made popular designs for and were able to sell many during the events.',
      'I enjoyed this project because it allowed us to be creative and come up with our own ideas. It was also very fun to be able to make money and see the results of our work. I also enjoyed the competition aspect, as it allowed us to compete with other groups and see what they were doing. Being able to go out and sell our products was also a very fun experience, as it allowed us to interact with the community and see what they thought of our products. The one thing I would change would be giving us a little more time, because it was a very short time period and we had to rush to get everything done. I would have appreciated having more time to test our products and iterate on them.'
    ]
  },
  {
    'href': 'new-biz',
    'display': 'New Biz',
    'image': maternityThrone,
    'rating': 5,
    'content': [
      'The New Biz project was the last group project of the semester. During this project we were able to create a more fleshed-out business model, and had to pitch it to a group of judges. I enjoyed this project because my team was really great, and everybody worked hard towards the end goal, resulting in us getting the highest score in our class. We had to do a lot of research into maternity products, which was a field I previously knew nothing about, so it was fun learning about something that I otherwise would have never seeked out. We also contacted many mothers and midwives for customer research, which gave me a lot of useful experience in conducting interviews and surveys, and highlighted the importance of customer research in developing a product.',
      'Implementing our solution was a gratifying experience, as it directly tackled a real-world issue with the potential to significantly benefit mothers. The development of a chair facilitating the squatting position during childbirth addressed a crucial aspect of maternal health. It was fulfilling to contribute to a solution that not only addressed the practical challenges of the birthing process but also aimed to raise awareness about the safer and more comfortable alternative of giving birth in a squatting position. Our project underscored the importance of innovation in healthcare, especially when it comes to solutions that can enhance the well-being of individuals in meaningful and impactful ways.'
    ],
  }
]

function App() {
  return (
    <div id="app">
      <NavBar links={sections} />
      <Hero />
      {
        sections.map((section, i) => 
          <ProjectSection align={i % 2 === 0 ? 'left': 'right'} key={i} id={section.href} title={section.display} image={section.image} content={section.content} rating={section.rating} />
        )
      }
    </div>
  );
}

export default App;
